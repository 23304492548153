:root {
  --light-background-color: #f6f6f6;
  --dark-background-color: #ededed;
  --primary-color: #1976d2;
  --secondary-color: #4098ef;
  --accent-color: #2b2d31;
  --text-color: #ffffff;
}

.App {
  text-align: center;
  background-color: var(--light-background-color);
}

.AppWrapper {
  /* margin-top: 16px; */
}
