.CalendarFullContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: rgb(224, 224, 224);
}

.CalendarContainer {
    width: 75%;
    /* max-height: 90vh; */
    background-color: white;
}

.CalendarHeader {
    margin: 5px;
    display: flex;
    justify-content: space-between;
}

.CalendarPanelContainer {
    display: flex;
    flex-direction: row;
    gap: 0px;
    width: 100%;
}