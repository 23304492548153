.PostItemsContainer {
  display: flex;
  flex-direction: row;
}

.UserInfoContainer {
  width: 120px;
  padding: 5px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.PostTitle {
  width: 100%;
}

.LinksContainer {
  margin-top: 10px;
}

.PostMiddleContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.PostMiddleContainer > .PostInfo {
  margin-right: 8px;
}

.PostFooter {
  width: 95%;
  margin: 10px auto;
}

.PostButtonContainer {
  display: flex;
  flex-direction: row-reverse;
  height: 30px;
  padding: 0.25rem;
  margin-right: auto;
  margin-left: auto;
}

.CommentInputContainer {
  display: flex;
  flex-direction: row;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.CommentInputContainer {
  display: "flex";
  flex-direction: "column";
  padding: 0.5rem;
  align-items: center;
}

.CommentListContainer {
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
}

.CommentContainer {
  display: flex;
  flex-direction: row;
}

.CommentBox {
  display: flex;
  flex-direction: row;
  background-color: #f6f6f6;
  height: fit-content;
  width: 90%;
  text-align: left;
  padding: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
  border-radius: 8px;
}

.CommentUserInfoContainer {
  display: flex;
  flex-direction: row;
  height: 40px;
  margin-bottom: 5px;
  align-items: center;
}
