.LinkForm {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.LinkInputContainer {
  margin-bottom: 20px;
}

.FooterContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;

  .ErrorMessage {
    padding: 0;
    margin: 0;
    margin-right: 15px;
    color: #c70d0d;
  }

  .SuccessMessage {
    padding: 0;
    margin: 0;
    margin-right: 15px;
    color: #0186d9;
  }
}
