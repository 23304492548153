.ScheduleContainer {
    height:fit-content;
}

.CalendarEvent {
    display: flex;
    flex-direction: column;
    padding: 0px;
}

.CalendarEventCourseCode {
    margin: 5px;
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 500;
    color: black;
}

.CalendarEventLocation {
    text-align: center;
    vertical-align: middle;
    font-size: 12px;
    color: black;
    white-space: normal;
}

/* Syncfusion Calendar Styling */

@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";

/* Code for trying to make the cells fit screen properly. Not responsive */
/* .e-schedule .e-vertical-view .e-time-cells-wrap table td,
.e-schedule .e-vertical-view .e-work-cells {
    height: 2.75vh;
    min-height: 20px;
} */

/* Hide the date numbers */
.e-schedule .e-vertical-view .e-header-cells .e-header-date {
    display: none;
  }

/* Make date text bigger */
.e-schedule .e-vertical-view .e-header-cells .e-header-day {
    font-size: large;
}

/* Remove padding from event */
.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-appointment-details {
    padding: 0px;
}

/* Ensure the parent .e-appointment-details and .e-appointment have full height */
.e-appointment-details, .e-appointment {
    height: 100%;
  }



