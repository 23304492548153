.CalendarSessionSelector{
    display: flex;
    align-items: center;
    height: 20px;
}

.CalendarSelectorGroup {
    padding-right: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 20px;
}

.CalendarSelectorContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
}

.CalendarSelectorGroupContainer {
    display: flex;
}