.SectionInfoPanelContainer {
    display: flex;
    flex-direction: column;
    width: 25vw;
    align-items: baseline;
    background-color: #f0f0f0;
  }

.SectionCourseHeader {
    text-align: left;
    width: 25vw;
    padding-top: .5rem;
    padding-bottom: .25rem;
    color: #1f1f1f;
    font-weight: 600;
    font-size: 1.75rem;
    background-color: #dddddd;
    padding-left: .75rem;
}

.SectionDataHeader {
    padding-top: .75rem;
    padding-left: .75rem;
    font-size: 1.25rem;
    width: 25vw;
    text-align: left;
}

.SectionData {
    display: flex;
    flex-direction: row;
    padding-left: .75rem;
    padding-top: .25rem;
    text-align: left;
}

.MapContainer {
    padding-left: .75rem;
    padding-right: .75rem;
    width: 25vw;
    height: 25%;
}

